@import "@/assets/scss/style.scss";

.payment-switcher {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    gap: 1rem;
    width: 100%;

    &__offers {
        width: 100%;
    }

    &__offers-select {
        width: 100%;
    }
}
