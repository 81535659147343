@import "@/assets/scss/style.scss";

.paymentRecordsTableWrapper {
    &::v-deep {
        .ant-table-thead {
            tr {
                th {
                    @extend .u-b2--;
                    color: $grey-7;
                    background: $white-two;
                    padding: 8px;
                }
            }
        }

        .ant-table-tbody {
            tr {
                td {
                    padding: 16px 8px;
                }
            }
        }
    }

    span {
        &::v-deep {
            button {
                color: $grey-9;

                &:last-child {
                    margin-left: 8px;
                }
            }
        }
    }
}
